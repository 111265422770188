<template>
    <div class="dataListPage" v-if="pageObj.beforeafter">
        <!-- 头部大图 -->
        <div class="dlBigImg">
            <img :src="pageObj.advert_image ? pageObj.advert_image : 'http://iv.vu818.com/img/wzyt.jpg'" ref="box" />
        </div>
        <!-- 文章内容 -->
        <div class="dlpBox">
            <!-- 标题 -->
            <div class="dlpTitle" :style="{ 'margin-top': marginTop }">
                <!-- 上一篇下一篇 -->
                <div class="dlpBackNext">
                    <div class="dlpBack" :class="pageObj.beforeafter.before_id ? '' : 'default'" @click="prev">
                        <div><img src="https://iv.vu818.com/img/vu818.png" /></div>
                        <span>上一篇：{{ pageObj.beforeafter.before_title }}</span>
                    </div>
                    <div class="dlpNext" :class="pageObj.beforeafter.after_id ? '' : 'default'" @click="next">
                        <span>下一篇：{{ pageObj.beforeafter.after_title }}</span>
                        <div><img src="https://iv.vu818.com/img/vu818.png" /></div>
                    </div>
                </div>
                <div class="dlpBig">{{ pageObj.title }}</div>
                <div class="dlpSmall">
                    <p class="dsTime">{{ pageObj.create_time }}</p>
                    <!-- <div class="crLook">
                        <div class="clImg">
                            <img src="http://iv.vu818.com/img/vu818.png" />
                        </div>
                        <p>{{ pageObj.look_nums }}</p>
                    </div> -->
                </div>
                <!-- 简介 -->
                <p>
                    <img src="https://iv.vu818.com/img/vu818dj2.png" />
                    {{ pageObj.intro }}
                </p>
                <img src="https://iv.vu818.com/img/vu818dj1.png" />
            </div>
            <!-- 内容 -->
            <div class="dlpContent">
                <template v-if="pageObj.sift == 0">
                    <!-- 富文本内容 -->
                    <div class="editor-p" v-html="pageObj.ueditorData"></div>
                </template>
                <template v-else-if="pageObj.sift == 1">
                    <!-- 板块内容 -->
                    <div class="bk">
                        <!-- 上 -->
                        <ul class="top">
                            <h2>{{ pageObj.plate_top }}</h2>
                            <li v-for="el in pageObj.plate.filter(e => e.td == 1)" :key="el.id">
                                <img :src="el.image" />
                                <div>
                                    <span>{{ el.title }}</span>
                                    <p>{{ el.intro }}</p>
                                </div>
                            </li>
                        </ul>
                        <!-- 下 -->
                        <ul class="bottom">
                            <h2>{{ pageObj.plate_bottom }}</h2>
                            <li v-for="e in pageObj.plate.filter(e => e.td == 2)" :key="e.id">
                                <!-- <span>{{ 10 > i + 1 ? '0' + (i + 1) : i + 1 }}</span> -->
                                <img :src="e.image" />
                                <div>
                                    <span>{{ e.title }}</span>
                                    <p>{{ e.intro }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>
            </div>
            <!-- 底部推荐的相关解决方案 -->
            <div class="dlpFooter" v-if="tabList.length">
                <h1 style="text-align: center; margin: 40px 0px 15px">客户案例</h1>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <template v-for="el in tabList">
                        <el-tab-pane v-if="el.children.length != 0" :key="el.name" class="tb" :label="el.name" :name="el.name">
                            <div>
                                <!-- 左边部分 -->
                                <ul class="icons">
                                    <li
                                        v-for="(item, i) in el.children"
                                        :key="i"
                                        @click="sele(item.id)"
                                        :class="{ ac: item.id === activeId }"
                                    >
                                        <img :src="item.iconSrc" />
                                    </li>
                                </ul>
                                <h3>打造贵阳千亿级产业集群</h3>
                                <p>{{ activeObj.comment }}</p>
                                <p>—— {{ activeObj.commentator }}</p>
                                <button @click="goDetail(activeObj)">了解详情</button>
                            </div>
                            <div>
                                <!-- 右边图片部分 -->
                                <img class="rBg" :class="{ out2: kg }" ref="rBg" :src="activeObj.bgSrc" />
                                <img class="img" :class="{ out1: kg }" ref="img" :src="activeObj.imgSrc" />
                            </div>
                        </el-tab-pane>
                    </template>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "SolutionDetails",
    data() {
        return {
            // 页面当前的数据对象
            pageObj: {},
            tabListtabList: [
                {
                    name: "工业互联网",
                    children: [
                        {
                            comment:
                                "运用威有智能云AI技术，助推贵阳企业上云、生产上云、管理上云，打造制造业智能化转型服务生态圈，为贵阳市数字经济和工业互联网高质量发展贡献力量。",
                            commentator: "贵阳经济开发区",
                            bgSrc: "http://iv.vu818.com/img/9944577.jpg",
                            imgSrc: "http://iv.vu818.com/img/ae4704f.png",
                            iconSrc: "http://iv.vu818.com/img/kll.png",
                            id: "01",
                        },
                        {
                            comment:
                                "运用威有智能云AI技术，助推贵阳企业上云、生产上云、管理上云，打造制造业智能化转型服务生态圈，为贵阳市数字经济和工业互联网高质量发展贡献力量。",
                            commentator: "贵阳经济开发区",
                            bgSrc: "http://iv.vu818.com/img/1db1c54.png",
                            imgSrc: "http://iv.vu818.com/img/56fcb29.png",
                            iconSrc: "http://iv.vu818.com/img/kll.png",
                            id: "02",
                        },
                        {
                            comment:
                                "运用威有智能云AI技术，助推贵阳企业上云、生产上云、管理上云，打造制造业智能化转型服务生态圈，为贵阳市数字经济和工业互联网高质量发展贡献力量。",
                            commentator: "贵阳经济开发区",
                            bgSrc: "http://iv.vu818.com/img/9944577.jpg",
                            imgSrc: "http://iv.vu818.com/img/ae4704f.png",
                            iconSrc: "http://iv.vu818.com/img/kll.png",
                            id: "03",
                        },
                    ],
                },
            ],
            activeName: "工业互联网",
            activeId: "",
            kg: false,

            marginTop: "",
        };
    },
    created() {
        if (!this.$route.query.id) {
            // 没有携带id的情况
            this.$router.push("/Solution");
        }
    },
    methods: {
        // tab点击
        handleClick({ index }) {
            // tab切换后默认取第一个
            this.activeId = this.tabList[index].children[0].id;
        },
        // 点击三个中的一个
        sele(id) {
            if (this.kg || this.activeId === id) return;
            // 开关操作动画
            this.kg = true;
            setTimeout(() => {
                this.kg = false;
                this.activeId = id;
            }, 300);
        },
        // 获取详情数据
        async getData() {
            let { data } = await axios.get("/api/solution/beforeafter", { params: { id: this.$route.query.id } });
            return data;
        },
        // 点击了解详情
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            this.$router.push({
                name: "listDetail",
                params: { id },
            });
        },
        // 上一个
        prev() {
            let id = this.pageObj.beforeafter.before_id;
            if (!id) return;
            this.$bus.$emit("openLoading");
            this.$router.push("/SolutionDetails?id=" + id);
        },
        // 下一个
        next() {
            let id = this.pageObj.beforeafter.after_id;
            if (!id) return;
            this.$bus.$emit("openLoading");
            this.$router.push("/SolutionDetails?id=" + id);
        },
    },
    computed: {
        activeObj() {
            let ob = {};
            this.tabList.forEach(el => {
                el.children.forEach(e => {
                    if (e.id == this.activeId) {
                        ob = e;
                    }
                });
            });
            return ob;
        },
        // 客户案例
        tabList() {
            if (!this.pageObj.project_list) return [];
            let arr = this.pageObj.project_list.children.map(e => {
                return {
                    name: e.category_title,
                    children: e.children
                        .map(el => {
                            return {
                                comment: el.keyword,
                                commentator: el.title,
                                bgSrc: el.little_image,
                                imgSrc: el.size_image,
                                iconSrc: el.logo,
                                id: el.id,
                                ueditorData: el.ueditorData,
                                project_url: el.project_url,
                            };
                        })
                        .filter(e => e.id),
                };
            });
            if (!arr.length) return arr;
            this.activeName = arr[0].name;
            this.activeId = arr[0].children[0] ? arr[0].children[0].id : "";
            return arr;
        },
    },
    watch: {
        $route: {
            immediate: true,
            async handler(newVal) {
                // console.log("jt");
                // 处理从详情页跳回列表页
                if (newVal.path != "/SolutionDetails") return;
                if (!newVal.query.id) return this.$router.replace("/Solution");
                if (this.pageObj.id == newVal.query.id) return;
                this.pageObj = await this.getData();
                this.$nextTick(() => {
                    setTimeout(() => {
                        // 遮住大图三分之一
                        this.marginTop = `-${this.$refs.box.offsetHeight / 3}px`;
                        // console.log(this.$refs.img.offsetHeight);
                    }, 100);
                });
            },
        },
    },
};
</script>

<style lang="less" scoped>
.dataListPage {
    // 头部大图
    .dlBigImg {
        width: 100%;
        height: 65vh;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    // 文章内容
    .dlpBox {
        width: 100%;
        margin: 0 auto;
        // 标题
        .dlpTitle {
            width: 100%;
            // height: 100px;
            // height: 258px;
            max-width: 1200px;
            margin: 0 auto;
            border-radius: 8px;
            box-shadow: 0px 9px 14px rgba(245, 245, 247, 0.8);
            // margin-top: -100px;
            position: relative;
            z-index: 99;
            background-color: white;
            padding: 40px;
            .dlpBackNext {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                position: absolute;
                left: 0px;
                top: -50px;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                .default {
                    cursor: default !important;
                    &:hover {
                        color: black !important;
                        text-decoration: none !important;
                    }
                }
                .dlpBack,
                .dlpNext {
                    cursor: pointer;
                    padding: 0 25px;
                    position: relative;
                    & > div {
                        position: absolute;
                        width: 30px;
                        height: 50px;
                        overflow: hidden;
                        transform: scale(0.3);
                        & > img {
                            position: absolute;
                            width: 1200px;
                        }
                    }
                    &:hover {
                        color: #7781f1;
                        text-decoration: underline;
                    }
                }
                .dlpBack {
                    & > div {
                        left: 0px;
                        top: 0px;
                        & > img {
                            top: -643px;
                            left: -176px;
                        }
                    }
                }
                .dlpNext {
                    & > div {
                        right: 0px;
                        top: 0px;
                        & > img {
                            top: -643px;
                            left: -208px;
                        }
                    }
                }
            }
            & > img {
                position: absolute;
                right: -40px;
                bottom: -40px;
            }
            & > p {
                text-align: left;
                text-indent: 36px;
                font-size: 18px;
                line-height: 34px;
                position: relative;
                & > img {
                    position: absolute;
                    width: 60px;
                    top: -22px;
                    left: 0px;
                }
            }
            .dlpBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                font-weight: 900;
                text-align: center;
            }
            .dlpSmall {
                width: 50%;
                height: 50px;
                line-height: 50px;
                margin: 0 auto;
                text-align: center;
                font-size: 20px;
                color: gray;
                display: flex;
                justify-content: space-evenly;

                .crLook {
                    .clImg {
                        width: 30px;
                        height: 85%;
                        float: left;
                        position: relative;
                        overflow: hidden;
                        img {
                            width: 1000px;
                            height: 1500px;
                            position: absolute;
                            top: -41px;
                            right: -309px;
                        }
                    }
                    p {
                        float: left;
                    }
                }
                .iconfont {
                    width: 40%;
                    height: 50px;
                    float: left;
                    font-size: 20px;
                }
            }
        }
        // 内容
        .dlpContent {
            width: 100%;
            margin-top: 50px;
            text-align: left;
            .bk {
                max-width: 1200px;
                margin: 0 auto;
                .top {
                    background-color: rgb(249, 250, 255);
                    border-radius: 8px;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 120px 0 0 40px;
                    position: relative;
                    h2 {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 40px;
                        font-size: 34px;
                    }
                    & > li {
                        width: 33.3%;
                        display: flex;
                        padding-right: 40px;
                        margin-bottom: 40px;
                        & > img {
                            // width: 50%;
                            max-width: 80px;
                            max-height: 80px;
                            margin-right: 20px;
                        }
                        & > div {
                            flex: 1;
                            span {
                                font-size: 20px;
                            }
                            p {
                                font-size: 16px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .bottom {
                    display: flex;
                    flex-direction: column;
                    h2 {
                        text-align: center;
                        margin: 40px 0 60px;
                        font-size: 34px;
                    }
                    & > li {
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #f7f7f9;
                        padding-bottom: 40px;
                        margin-top: 40px;
                        img {
                            width: 50%;
                        }
                        & > div {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding-left: 40px;
                            span {
                                font-size: 34px;
                                font-weight: bold;
                            }
                            p {
                                font-size: 18px;
                                margin-top: 40px;
                            }
                        }
                    }
                    & > li:nth-last-of-type(1) {
                        border: none;
                    }
                }
            }
        }
    }
}
.dlpFooter {
    text-align: left;
    // max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    /deep/.el-tabs__item {
        padding: 0 30px;
        font-size: 16px;
        &:hover {
            color: #7781f1;
        }
    }
    /deep/.el-tabs__nav-wrap {
        &::after {
            background-color: transparent;
        }
        padding: 0 30px;
    }
    /deep/.el-tabs__nav {
        .is-active {
            color: #7781f1;
        }
    }
    /deep/.el-tabs__active-bar {
        background-color: #7781f1;
    }
    .tb {
        display: flex;
        height: 450px;

        & > div {
            width: 50%;
        }
        & > div:nth-of-type(1) {
            background-color: #e9ecf5;
            padding: 50px;
            font-size: 14px;
            .icons {
                display: flex;
                .ac {
                    background-color: rgba(255, 255, 255, 1) !important;
                }
                li {
                    flex: 1;
                    margin-right: 2px;
                    background-color: rgba(255, 255, 255, 0.7);
                    border-radius: 3px;
                    cursor: pointer;
                    height: 70px;
                    line-height: 70px;
                    text-align: center;
                    &:hover {
                        background-color: rgba(255, 255, 255, 1);
                    }
                    img {
                        height: 100%;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            h3 {
                font-size: 24px;
                font-family: PingFangSC-Semibold;
                color: #222;
                line-height: 34px;
                font-weight: 600;
                margin-top: 30px;
            }
            p {
                line-height: 24px;
                height: 50px;
                padding-right: 70px;
                line-height: 24px;
                font-weight: 400;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                margin-top: 30px;
            }
            button {
                padding: 6px 24px;
                border-radius: 5px;
                cursor: pointer;
                border: 1px solid black;
                background-color: rgba(255, 255, 255, 0);
                &:hover {
                    border: 1px solid #7781f1;
                    background-color: #7781f1;
                    color: white;
                }
            }
        }
        & > div:nth-of-type(2) {
            position: relative;
            .img {
                position: absolute;
                bottom: 0px;
                left: -100px;
                height: 100%;
                opacity: 1;
                animation: an1 0.3s;
                @keyframes an1 {
                    0% {
                        transform: translateY(40px);
                        opacity: 0;
                    }
                    100% {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                }
            }
            .rBg {
                max-width: 100%;
                max-height: 450px;
                object-fit: cover;
                opacity: 1;
                animation: an2 0.3s;
                @keyframes an2 {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
            }
            .out1 {
                opacity: 0;
                animation: out1 0.3s;
                @keyframes out1 {
                    0% {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                    100% {
                        transform: translateY(40px);
                        opacity: 0;
                    }
                }
            }
            .out2 {
                opacity: 0;
                animation: out2 0.3s;
                @keyframes out2 {
                    0% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
</style>
